import {
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cibFlickr,
    cibTumblr,
    cibXing,
    cibGithub,
    cibStackOverflow,
    cibYoutube,
    cibDribbble,
    cibInstagram,
    cibPinterest,
    cibVk,
    cibYahoo,
    cibBehance,
    cibReddit,
    cibVimeo,
    cibCcMastercard,
    cibCcVisa,
    cibStripe,
    cibPaypal,
    cibGooglePay,
    cibCcAmex,
    cilEyedropper,
    cibEyeem,
    cibOpenstreetmap,
    cilAccountLogout,
    cilSun,cilPlus
} from '@coreui/icons'
import {
    cifUs,
    cifBr,
    cifIn,
    cifFr,
    cifEs,
    cifPl
} from '@coreui/icons'
import {
    cilArrowRight,
    cilBan,
    cilBasket,
    cilBell,
    cilCalculator,
    cilCalendar,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronTop,
    cilCheckCircle,
    cilCommentSquare,
    cilCursor,
    cilDrop,
    cilDollar,
    cilEnvelopeClosed,
    cilEnvelopeOpen,
    cilEuro,
    cilGlobeAlt,
    cilGrid,
    cilFile,
    cilJustifyCenter,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMoon,
    cilOptions,
    cilPencil,
    cilPeople,
    cilPuzzle,
    cilSettings,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilStar,
    cilTask,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilXCircle,
    cilChildFriendly,
    cilHospital,
    cilBuilding,
    cilInstitution,
    cilBarChart,
    cilChartLine,
    cilApplicationsSettings,
    cilHouse,
    cilTrash

} from '@coreui/icons'

import { logo } from './logo'

export const iconsSet = Object.assign({}, { logo }, {
    cilArrowRight,
    cilBan,
    cilBasket,
    cilInstitution,
    cilApplicationsSettings,
    cilBarChart,
    cilChartLine,
    cilBell,
    cilCalculator,
    cilCalendar,
    cilCloudDownload,
    cibEyeem,
    cibOpenstreetmap,
    cilAccountLogout,
    cilSun,
    cilPlus,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilBuilding,
    cilChevronTop,
    cilCheckCircle,
    cilCommentSquare,
    cilCursor,
    cilDrop,
    cilDollar,
    cilEnvelopeClosed,
    cilEnvelopeOpen,
    cilEuro,
    cilGlobeAlt,
    cilChildFriendly,
    cilGrid,
    cilFile,
    cilJustifyCenter,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMoon,
    cilOptions,
    cilPencil,
    cilPeople,
    cilPuzzle,
    cilSettings,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilStar,
    cilTask,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilXCircle,
    cilHospital,
    cilHouse,
    cilTrash,
    cilEyedropper
}, {
    cifUs,
    cifBr,
    cifIn,
    cifFr,
    cifEs,
    cifPl
}, {
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cibFlickr,
    cibTumblr,
    cibXing,
    cibGithub,
    // cibStackOverflow,
    cibYoutube,
    cibDribbble,
    cibInstagram,
    cibPinterest,
    cibVk,
    cibYahoo,
    cibBehance,
    cibReddit,
    cibVimeo,
    cibCcMastercard,
    cibCcVisa,
    cibStripe,
    cibPaypal,
    cibGooglePay,
    cibCcAmex
})